import React, { useState } from "react";

import {
    NotificationItem
} from "./styles";
import api from "../../../../../services/api";
import DetalharModal from "../DetalharModal";


interface IProps {
    usuario: string;
    id: number;
    load: () => void;
}

const Notification = ({ usuario, id, load}: IProps) => {
    const [show, setShow] = useState(false);
    const [usuarioEditar, setUsuarioEditar] = useState();

    const loadUsuario = async () => {
        try {
            const { data } = await api.get(`usuarios/${id}`);
            setUsuarioEditar(data)
            
        } catch (error) {
            console.error("Erro ao carregar o usuário:", error);
        }
    };

    const toggleDetalhar = () => {
        loadUsuario()
        console.log("sjdksjdskjdk")
        setShow(!show);
    };
    return (
        <>
            <NotificationItem onClick={toggleDetalhar}>
                <p>Colaborador(a) <span>{usuario}</span> está próximo(a) da data de expiração. Clique aqui para atualizar as informações do usuário.</p>
            </NotificationItem>

            {show && (
                <DetalharModal
                    isOpen={show}
                    toggle={toggleDetalhar}
                    row={usuarioEditar}
                    loadUsuarios={load}
                />
            )}
        </>
    );
};

export default Notification;
