import React, { useMemo, useCallback, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { dialogBox } from "../../utils/dialogBox";

import { login, isAuthenticated } from "../../../services/auth";

import {
  Container,
  LoginCard,
  Logo,
  CustomRiLoginBoxLine,
  LoginFooter,
  SubTitle,
} from "./styles";

//import logoImage from "../../assets/logo/logo.png";
import logoImage from "../../assets/logo/logotest1.png";
import logoEstado from "../../assets/logo/logo_estado.png";
import backgroundImage from "../../assets/bgtest3.png";

import api from "../../../services/api";

interface Iprops {
  logoName?: string;
  initialPagePath: string;
  backGroundImg?:string
  subtitleModulo?: string;

}

interface ILogin {
  username: string;
  password: string;
}

const Login = ({ logoName, initialPagePath, backGroundImg, subtitleModulo }: Iprops) => {
  const [logging, setLogging] = useState<boolean>(false);
  const history = useHistory();
  const { register, handleSubmit, errors } = useForm<ILogin>();

  // Função para chamar a caixa de diálogo
  const notify = useCallback(
    (type: string, message: string) => dialogBox(type, message),
    []
  );

  const logo = useMemo(() => {
    return logoName ? require(`../../assets/logo/${logoName}.png`) : logoImage;
  }, [logoName]);

  const backGround = useMemo(() => {
    return backGroundImg ? require(`../../assets/${backGroundImg}.png`) : backgroundImage;
  }, [backGroundImg]);

  const onSubmit = useCallback(async (formData) => {
    if (logging) return;
    try {
      setLogging(true);
      const { data } = await api.post("login/", formData);
      login(data);
      history.push(initialPagePath);
    } catch {
      notify("error", "Credenciais Inválidas");
    }
    setLogging(false);
  }, []);
  
  return isAuthenticated() ? (
    <Redirect to={initialPagePath} />
  ) : (
    <Container backGround = {backGround}>
      <LoginCard>
        <Logo logo={logo} />
        <SubTitle>
        {subtitleModulo &&
         <>
            {localStorage.setItem('module', subtitleModulo)}
            <p>{subtitleModulo}</p>'
         </> }
        </SubTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <input
            className={errors.username && "error-input"}
            type="text"
            name="username"
            placeholder="Login"
            ref={register({ required: true })}
          />
          {errors.username && (
            <span className="error-message">Login inválido</span>
          )}
          <input
            className={errors.password && "error-input"}
            type="password"
            name="password"
            placeholder="Senha"
            ref={register({ required: true })}
          />
          {errors.username && (
            <span className="error-message">Senha inválida</span>
          )}
          <div>
            <button type="submit" style={{backgroundColor:'rgb(60,60,60)'}}>
              <CustomRiLoginBoxLine />
              Entrar
            </button>
            {/* <span>Esqueci a password</span> */}
          </div>
        </form>
      </LoginCard>
      <LoginFooter>
        <div>
          <img src={logoEstado} />
          <p>GOVERNO DO ESTADO DO RIO GRANDE DO NORTE</p>
          <p>SECRETARIA DE ESTADO DO TRABALHO, DA</p>
          <p>HABITAÇÃO E DA ASSISTÊNCIA SOCIAL - SETHAS</p>
        </div>
      </LoginFooter>
    </Container>
  );
};

export default Login;
